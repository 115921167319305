<template>
    <div class="container">
        <v-row class="">
            <v-col cols="12" class="top-panel px-0 py-0 ml-4">
                <div class="arrow-btn">
                    <!-- <img src="../assets/img/arrowBack.svg" class="arrow-img" alt="arrow-back" /> -->
                    <p class="nav-title">Аналитика</p>
                </div>
            </v-col>
        </v-row>
        <div class="tabs">
            <div class="">
                <v-toolbar flat id="custom-toolbar" style="height: 100% !important;">
                    <div class="toolbar-container">
                        <div>
                            <v-toolbar-title class="title">Выручка по каналам продаж
                            </v-toolbar-title>
                        </div>
                        <div class="toolbar-buttons-container">
                            <div class="col-4 restaurant-input">
                                <div class="search-component">
                                    <v-autocomplete v-model="selectedRestaurant" :items="restaurantsList"
                                        item-text="restaurant.name" item-value="restaurantId" outlined
                                        label="Выберите ресторан" hide-details solo clearable id="restaurant-selector"
                                        placeholder="Выберите ресторан" class="custom-scroll"
                                        @change="getDashboardInformation">
                                        <template v-slot:item="{ item, isSelected }">
                                            <div :class="['restaurant-item', { 'active': isSelected }]">
                                                {{ item.restaurant.name }} {{ item.restaurant.address ?
                                                    `(${item.restaurant.address})` : '' }}
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                </div>

                            </div>
                            <div class="date-picker" id="date-picker2">
                                <v-text-field v-model="formattedDateRangeProd"
                                    :label="`Период сравнения: ${formattedCompareDataRangeProd}`" readonly outlined
                                    @click="dialog = true" сlass="custom-text-field" hide-details="auto">
                                    <!-- Иконка календаря слева -->
                                    <template v-slot:prepend-inner>
                                        <v-icon сlass="mt-0">mdi-calendar</v-icon>
                                    </template>
                                </v-text-field>

                                <!-- Диалог с календарем -->
                                <v-dialog v-model="dialog" max-width="290">
                                    <v-card id="date-picker-body">
                                        <v-card-actions class="d-flex justify-center">

                                            <v-btn :class="['btn-exel-callendar', 'mx-2']"
                                                :color="dateType === 'month' ? 'primary' : 'grey darken-1'"
                                                @click="setDateType('month')">
                                                Месяц
                                            </v-btn>
                                            <v-btn :class="['btn-exel-callendar', 'mx-2']"
                                                :color="dateType === 'date' ? 'primary' : 'grey darken-1'"
                                                @click="setDateType('date')">
                                                Неделя
                                            </v-btn>
                                        </v-card-actions>
                                        <v-date-picker v-model="selectedDate" @input="onDateChange" scrollable
                                            :first-day-of-week="1" :allowed-dates="allowedDates" :type="dateType">
                                        </v-date-picker>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <button class="btn-exel" @click="getCompareDashboard()">
                                                Применить
                                            </button>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                            <div class="btn-container">
                                <!-- <button class="btn-exel" @click="toggleDateType">
                                    {{
                                    dateType === "month"
                                        ? "Недельное сравнение"
                                        : "Месячное сравнение"
                                }}
                                </button> -->

                                <!-- <vue-excel-xlsx class="btn-exel" :data="formattedData" :columns="columns"
                                    :file-name="getExcelFileName()" :file-type="'xlsx'"
                                    :sheet-name="'Выручка по каналам продаж'" title="Выгрузить в Excel">
                                    Выгрузить <img src="../assets/img/exel-btn.svg" alt="" />
                                </vue-excel-xlsx> -->


                            </div>
                        </div>
                    </div>
                </v-toolbar>
            </div>
        </div>
        <div v-if="noDataMessage" class="no-data-message">
            <div class="error-container d-flex align-center justify-center">
                <div class="chart-heading">
                    <p class="error-head">{{ noDataMessage }}</p>
                    <p class="error-heading">За выбранный период <b>{{ this.formattedDateRangeProd }} </b> отсуствуют
                        данные.</p>

                    <p class="error-heading">Попробуйте выбрать другой период или сменить ресторан.</p>
                </div>
            </div>
        </div>
        <div v-else-if="isLoading" class="loading-message d-flex align-center justify-center">
            <v-progress-circular indeterminate size="40" color="primary"></v-progress-circular>
        </div>
        <div v-else>
            <div class="first-container">
                <TableChart :dashboardData="dashboardInfo" />
                <!-- ['divider-custom', { 'divider-margin': !miniVariant }] -->
                <!-- :class="[{ 'doughnut-border': miniVariant, 'doughnut-border-mini-variant': !miniVariant }]" -->
                <div class="doughnut-border">
                    <h2 class="doughnut-name">Выручка</h2>
                    <DoughnutChart :dashboardData="dashboardInfo" />
                </div>
            </div>
            <div class="chart-container">
                <div class="chart-heading">
                    <p class="chart-head">Выручка по каналам продаж</p>
                    <p class="date-heading">за период {{ formattedDateRangeProd }}</p>
                </div>
                <div style="width: 100%; max-width: 1586px;">
                    <BarChart :dashboardData="dashboardInfo" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import BarChart from "../components/charts/BarChart.vue";
import DoughnutChart from "../components/charts/DoughnutChart.vue"
import moment from "moment";
import TableChart from "../components/charts/TableChart.vue";

export default {
    name: "Analytics",
    components: {
        BarChart,
        DoughnutChart,
        TableChart
    },

    data() {
        return {
            //selectedRestaurantName: '',
            miniVariant: false,
            miniVariantApplied: false,
            // filteredAccounts: '',
            selectedRestaurant: null, // Хранит выбранный ресторан
            restaurantsList: [], // Список ресторанов
            dashboardInfo: {
                // dailyRevenues: [],
            },
            // columns: [
            //     { label: 'Дата', field: 'date' },
            //     { label: 'Доставка', field: 'deliveryRevenue' },
            //     { label: 'На вынос', field: 'selfPickUpRevenue' },
            //     { label: 'В зале', field: 'inHouseRevenue' },
            //     { label: 'Общий доход', field: 'totalRevenue' },
            // ],
            noDataMessage: "",
            isLoading: true,
            dialog: false, // Скрываем календарь по умолчанию
            selectedDate: moment(new Date()).format('YYYY-MM-DD'), // Текущая выбранная дата
            dateType: "date", // Выбор по умолчанию: месяц
            // dataTypePeriod :['week', 'month','year'],
            dataTypePeriod: "",
            formattedDateRange: "",
            formattedCompareDataRange: "",
            formattedCompareDataRangeProd: "",
            formattedDateRangeProd: "",
            startDate: "",
            endDate: "", // Диапазон, отображаемый пользователю // Для отображения диапазона дат
        };
    },
    created() {
        this.setWeeklyDateRange();
        this.getDashboardInformation();
        this.getAllRestaurants();
        // this.restaurants.push(this.selectedRestaurant)
        console.log(this.restaurantsList);
        console.log("sda");
        if (this.restaurantsList.length === 1) {
            this.selectedRestaurant = this.restaurantsList[0].restaurantId;
        }

        // this.setInitialDateRange();
    },
    mounted() {
        this.getDashboardInformation();
        this.setInitialDateRange();

        // При загрузке страницы устанавливаем текущий месяц как диапазон
    },

    watch: {
        miniVariant(newVal) {
            if (newVal) {
                this.miniVariantApplied = true;
            }
        },
    },
    methods: {
        // onRestaurantChange(item) {
        //     // Здесь вы обновляете название ресторана
        //     const selectedRestaurantObj = this.restaurantsList.find(restaurant => restaurant.restaurantId === this.selectedRestaurant);
        //     if (selectedRestaurantObj) {
        //         this.selectedRestaurantName = selectedRestaurantObj.restaurant.name;
        //     } else {
        //         this.selectedRestaurantName = null;  // Это можно для ясности оставить, хотя оно и так будет null по умолчанию
        //     }
        //     // Вызываем метод для получения данных
        //     this.getDashboardInformation();
        // },
        // // Функция для выгрузки в Excel
        // getExcelFileName() {
        //     // Если название ресторана пустое, пишем "все рестораны"
        //     return `${this.formattedDateRangeProd}-${this.selectedRestaurantName || 'все рестораны'}`;
        // },
        formatDate(dateString) {
            const date = new Date(dateString); // Преобразуем строку в объект Date
            const day = String(date.getDate()).padStart(2, '0'); // Получаем день с ведущим нулем
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Получаем месяц с ведущим нулем
            const year = date.getFullYear(); // Получаем год

            return `${day}.${month}.${year}`; // Форматируем в DD.MM.YYYY
        },
        // getRestaurant(id) {
        //     this.$http
        //         .get(`api/Restaurant/${id}`).then((response) => this.selectedRestaurant = response.data).catch((error) => console.log(error))
        // },
        getRestaurantName(restaurantItem) {
            return restaurantItem?.restaurant?.name || "Без имени";
        },
        getRestaurantId(restaurantItem) {
            return restaurantItem?.restaurantId || null;
        },
        // getAllRestaurants() {
        //     let allRest = {
        //         restaurantId: null,
        //         restaurant: { name: "Все рестораны", className: "highlighted" } // Добавляем класс для стилизации
        //     };
        //     console.log(this.user.roles); // Для отладки
        //     this.restaurantsList = [...this.user.roles]; // Получаем рестораны из ролей пользователя
        //     this.restaurantsList.unshift(allRest); // Добавляем "Все рестораны" в начало
        //     console.log(this.restaurantsList);
        // },

        getAllRestaurants() {
            let allRest = { restaurantId: null, restaurant: { name: "Все рестораны" } };
            console.log(this.user.roles); // Для отладки

            // Получаем рестораны из ролей пользователя
            this.restaurantsList = [...this.user.roles];

            // Проверяем, если ресторанов больше одного, добавляем "Все рестораны" в начало списка
            if (this.restaurantsList.length > 1) {
                this.restaurantsList.unshift(allRest);
            }

            console.log(this.restaurantsList);
        },
        getCompareDashboard() {
            this.formattedDateRangeProd = this.formattedDateRange;
            this.formattedCompareDataRangeProd = this.formattedCompareDataRange;
            this.dialog = false;
            this.getDashboardInformation();
        },
        setWeeklyDateRange() {
            // Устанавливаем диапазон для текущей недели

            const today = moment(); // Текущая дата
            this.dataTypePeriod = 0;
            this.startDate = today.startOf('isoWeek').format("YYYY-MM-DD");
            this.endDate = today.endOf('isoWeek').format("YYYY-MM-DD");
            console.log(this.startDate);
            console.log(this.endDate);
            this.formattedDateRange = `${this.startDate} - ${this.endDate}`;
            this.formattedDateRangeProd = this.formattedDateRange;
            //   this.startDate = this.startDate.toISOString();
            //   this.endDate = this.endDate.toISOString();
        },
        getDashboardInformation() {
            this.isLoading = true;
            this.$http.get('/api/Dashboard/GetRestaurantRevenue', {
                params: {
                    restaurantId: this.selectedRestaurant,
                    startDate: this.startDate, // Укажите нужные даты или динамически
                    endDate: this.endDate,
                    dataType: this.dataTypePeriod

                }
            })
                .then((response) => {
                    if (response.status === 204 || !response.data || !response.data.totalChannelRevenues.length) {
                        this.noDataMessage = 'За выбранный период данных нет.';
                        this.dashboardInfo = {};
                    } else {
                        this.dashboardInfo = response.data;
                        this.noDataMessage = '';
                        this.isLoading = false;// Очистить сообщение, если данные есть
                    }
                })
                // Сохраните ответ в вашем data или обработайте его
                .catch((error) =>
                    (console.error('Error fetching dashboard information:', error))
                );
        },
        setInitialDateRange() {
            if (this.dateType === 'month') {
                this.dataTypePeriod = 1;
                // Устанавливаем диапазон для текущего месяца
                const startDate = moment(this.selectedDate).startOf("month").format("DD.MM.YYYY");
                const endDate = moment(this.selectedDate).endOf("month").format("DD.MM.YYYY");
                this.startDate = moment(this.selectedDate).startOf('month').format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate).endOf('month').format("YYYY-MM-DD");

                // Вычисляем предыдущий месяц
                const previousMonth = moment(this.selectedDate).subtract(1, 'month');
                console.log(previousMonth);

                const previousMonthStart = moment(previousMonth).startOf('month').format("DD.MM.YYYY");
                console.log(previousMonthStart);
                const previousMonthEnd = moment(previousMonth).endOf('month').format("DD.MM.YYYY");
                console.log(previousMonthEnd);
                this.formattedCompareDataRange = `${previousMonthStart} - ${previousMonthEnd}`;
                this.formattedCompareDataRangeProd = this.formattedCompareDataRange;
                // Сравнение текущего и предыдущего месяца
                //this.formattedDateRange = `Текущий месяц: ${startDate} - ${endDate}, Предыдущий месяц: ${previousMonthStart} - ${previousMonthEnd}`;
            } else if (this.dateType === 'date') {
                if (!moment(this.selectedDate, 'YYYY-MM-DD', true).isValid()) {
                    this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
                }
                this.dataTypePeriod = 0;
                const startOfWeek = moment(this.selectedDate)
                    .startOf("isoWeek")
                    .format("DD.MM.YYYY");
                const endOfWeek = moment(this.selectedDate)
                    .endOf("isoWeek")
                    .format("DD.MM.YYYY");


                const previousWeek = moment(this.selectedDate).subtract(1, 'isoWeek');


                const previousWeekStart = moment(previousWeek).startOf('isoWeek').format("DD.MM.YYYY");

                const previousWeekEnd = moment(previousWeek).endOf('isoWeek').format("DD.MM.YYYY");

                this.formattedCompareDataRange = `${previousWeekStart} - ${previousWeekEnd}`;
                this.formattedCompareDataRangeProd = this.formattedCompareDataRange;
                this.formattedDateRange = `${startOfWeek} - ${endOfWeek}`;
                this.formattedDateRangeProd = this.formattedDateRange;



            }
            // this.getDashboardInformation();
        },
        setDateType(dateType) {
            this.dateType = this.dateType === 'month' ? 'date' : 'month';
            // Устанавливаем выбранную дату в зависимости от типа
            if (this.dateType === 'month') {
                // Если переключаемся на месяц, используем текущий месяц
                this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
            } else {
                // Для недели используем текущую дату
                this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
            }
            // Устанавливаем диапазон дат
            this.setInitialDateRange();
        },
        // toggleDateType() {

        //     // Переключаем тип сравнения (месяц/неделя)
        //     this.dateType = this.dateType === 'month' ? 'date' : 'month';

        //     // Устанавливаем выбранную дату в зависимости от типа
        //     if (this.dateType === 'month') {
        //         // Если переключаемся на месяц, используем текущий месяц
        //         this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
        //     } else {
        //         // Для недели используем текущую дату
        //         this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
        //     }

        //     // Устанавливаем диапазон дат
        //     this.setInitialDateRange();

        //     // Оставляем диалог открытым только если он был открыт до этого
        // },
        onDateChange(date) {
            if (this.dateType === "month") {
                this.dataTypePeriod = 1;
                console.log(this.dataTypePeriod);
                // Если выбран месяц, устанавливаем диапазон на месяц
                const startDate = moment(date).startOf("month").format("DD.MM.YYYY");
                const endDate = moment(date).endOf("month").format("DD.MM.YYYY");
                this.startDate = moment(this.selectedDate).startOf('month').format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate).endOf('month').format("YYYY-MM-DD");
                const previousMonth = moment(this.selectedDate).subtract(1, 'month');
                console.log(previousMonth);

                const previousMonthStart = moment(previousMonth).startOf('month').format("DD.MM.YYYY");
                console.log(previousMonthStart);
                const previousMonthEnd = moment(previousMonth).endOf('month').format("DD.MM.YYYY");
                console.log(previousMonthEnd);
                this.formattedCompareDataRange = `${previousMonthStart} - ${previousMonthEnd}`
                this.formattedDateRange = `${startDate} - ${endDate}`;
            } else if (this.dateType === "date") {
                this.dataTypePeriod = 0;
                // Если выбрана неделя, вычисляем начало и конец недели
                const startOfWeek = moment(date).startOf("isoWeek").format("DD.MM.YYYY");
                const endOfWeek = moment(date).endOf("isoWeek").format("DD.MM.YYYY");
                this.startDate = moment(this.selectedDate)
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate)
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");


                const previousWeek = moment(this.selectedDate).subtract(1, 'isoWeek');


                const previousWeekStart = moment(previousWeek).startOf('isoWeek').format("DD.MM.YYYY");

                const previousWeekEnd = moment(previousWeek).endOf('isoWeek').format("DD.MM.YYYY");

                this.formattedCompareDataRange = `${previousWeekStart} - ${previousWeekEnd}`
                this.formattedDateRange = `${startOfWeek} - ${endOfWeek}`;
            }
        },
        allowedDates(date) {
            // Разрешаем выбирать только недели или месяцы
            if (this.selectedRestaurant) {
                const restaurant = this.restaurantsList.find(
                    item => item.restaurantId === this.selectedRestaurant
                );
                this.workStartDate = restaurant ? restaurant.restaurant.createdOn : null;
            } else {
                // Если ресторан не выбран, находим самую позднюю workStartDate среди всех ресторанов
                const earliestDate = this.restaurantsList.reduce((minDate, restaurant) => {
                    const currentStartDate = moment(restaurant.restaurant.createdOn);
                    return currentStartDate.isBefore(minDate) ? currentStartDate : minDate;
                }, moment(this.restaurantsList[0].restaurant.createdOn));
                this.workStartDate = earliestDate.toISOString();
            }
            const workStartMoment = moment(this.workStartDate);
            const currentMoment = moment();
            // Разрешаем только даты после workStartDate
            if (moment(date).isBefore(workStartMoment, 'month') || moment(date).isAfter(currentMoment, 'day')) {
                return false;
            }

            // Ограничиваем выбор дат в зависимости от типа
            if (this.dateType === "month") {
                return true;
            } else if (this.dateType === "date") {
                return moment(date).day() === 1; // Позволяем выбирать недели (по понедельникам)
            }

            return false;
        }
    },
    computed: {
        ...mapGetters({
            user: "user",
        }),
        // formattedData() {
        //     // Проверяем, есть ли данные и свойство dailyRevenues существует
        //     if (this.dashboardInfo && this.dashboardInfo.dailyRevenues) {
        //         return this.dashboardInfo.dailyRevenues.map((item) => ({
        //             ...item,
        //             date: this.formatDate(item.date) // Форматируем дату
        //         }));
        //     } else {
        //         return []; // Возвращаем пустой массив, если данных нет
        //     }
        // }
    }
};
</script>

<style scoped>
#date-picker2 > ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 5px;
}
#date-picker2 {
  color: red;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 12px;
}
.restaurant-item {

    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item:first-child {
    /* padding-left: 10px; */
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item.active {
    font-weight: bold;
    padding-left: 10px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgb(152, 162, 178);
}

.highlighted {
    font-weight: bold;
    padding-left: 10px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}





.doughnut-border {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 677px;
    width: 100%;
}

.doughnut-border-mini-variant {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 478px;
    width: 100%;
}

.doughnut-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 19px;
}

.row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.table-border {
    background-color: white;
}



.chart-head {
    font-family: Inter;
    font-size: 1.0416vw;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
}

.date-heading {
    font-family: Inter;
    font-size: 0.833vw;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
}


.chart-container {
    max-width: 1645px;
    width: 100%;
    margin: 20px 0px;
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.error-container {
    max-width: 50%;
    /* Задаем максимальную ширину контейнера */
    margin: 0 auto;
    margin-top: 20px;
    /* margin: 0px 20px; */
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.error-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: center;
}

.error-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
}

.tabs {
    padding: 0 30px 0px 0px;
}

.toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
    padding: 0;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-selected {
    border-radius: 5px;
    padding: 5px 18px;
    color: white;
    background: red;
    height: 36px;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    display: flex;
    align-items: center;
}

.btn-exel {
    border-radius: 5px;
    padding: 5px 18px;
    color: white;
    background: #323c4d;
    height: 36px;
    font-family: Inter;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

}

.btn-exel-callendar {
    border-radius: 5px !important;
    padding: 5px 18px !important;
    color: white !important;
    background: #323c4d !important;
    height: 36px !important;
    font-family: Inter !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;

}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    border-bottom: 0.5px solid rgb(212, 212, 225);
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 36px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
}

/* ::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 36px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224); 
    border-radius: 5px;
} */

::v-deep .theme--light.v-sheet {
    background-color: transparent;
    border-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

.arrow-img {
    cursor: pointer;
    margin-left: 16px;
}

.nav-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #98a2b2;
    margin-bottom: 0px;
    margin-left: 11px;
}

.arrow-btn {
    /* border-right: 1px solid var(--04, #eaeaea); */
    /* width: 56px;
    height: 100%; */
    display: flex;
    align-items: center;
}

.img-pluss-btn {
    margin-left: 10px;
}

.top-panel {
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}

.row-1:last-child {
    background-color: rgb(50, 60, 77);
    border-radius: 6px;
}

.price-white {
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    color: white;
}

.column-title-white {
    font-family: Inter;
    font-size: 13px;
    color: white;
    margin-bottom: 0px;
}

.four-container-title {
    color: white;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.third-container-title {
    color: #14bf91;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.second-container-title {
    color: #006d5a;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.col-four {
    width: 221px;
    max-width: 221px;
}

.col-third {
    width: 186px;
    max-width: 186px;
}

.col-second {
    width: 186px;
    max-width: 186px;
}

.col-first {
    width: 114px;
    max-width: 114px;
}

.container-padding-right {
    padding-right: 7px;
}

.container-padding-left {
    padding-left: 18px;
}

.shadow-box-container {
    border-radius: 8px;
}

.stats-container {
    display: flex;
    justify-content: space-between;
    border-left: none;
    border-right: none;
}

/* Добавляем бордеры только первому элементу */
.row-1 .stats-container:first-of-type {
    border-left: 1px solid #d4d4d4;
    /* border-right: 1px solid grey; */
}

/* Убираем бордеры у последнего элемента */
.row-1 .stats-container:last-of-type {
    /* border-left: none; */
    border-right: none;
}

.price-column {
    display: flex;
    flex-direction: column-reverse;
}

.percent-text-gray {
    color: #c1c1c1;
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 11px;
}

.price {
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    color: rgb(50, 60, 77);
}

.column-title {
    font-family: Inter;
    font-size: 13px;
    color: rgb(132, 132, 132);
    margin-bottom: 0px;
}

.first-container-title {
    color: #13414e;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.first-container {
    /* width: max-content; */
    display: flex;
    /* margin: 36px auto 0;
    max-width: 1645px; */

    gap: 22px;
}

.row-1 {
    align-items: center;
    display: flex;
    max-width: 1055px;
    padding: 9px 16px;
    margin-bottom: 10px;
    border-radius: 6px;
}


.title {
    font-family: Inter;
    font-weight: 700;
    font-size: 16px !important;
    color: rgb(50, 60, 77);
    margin-right: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 12px;
    min-width: 240px;
}

.btn-container :last-child {
    margin-left: 10px;
}

.custom-text-field {
    background-color: white;
    margin-bottom: 100px;
    /* Белый фон */
}

/* Центрирование иконки */
.calendar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;

}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}


::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 0.9vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}


::v-deep .v-list .v-list-item--active {
    color: white !important;
    font-family: Inter !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding-left: 10px !important;
    /* Толщина, стиль и цвет левой границы */
}

#date-picker-body {
    background: #ffffff;
}

#custom-toolbar ::v-deep .v-toolbar__content {
    padding-left: 0 !important;
    /* Убирает отступ слева */
}

/* @media screen and (max-width: 1491px) {
    .doughnut-border {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        max-width: 478px;
        width: 100%;
    }
} */


@media screen and (max-width:1000px) {

    .title {
        font-size: 14px !important;
    }
}

@media screen and (max-width:700px) {
    .chart-head {
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 24.2px;
        text-align: left;
    }

    .date-heading {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
    }

    .doughnut-border {
        padding: 20px;
        border-radius: 5px;

        display: flex;
        flex-direction: column;
        max-width: 90vw;
        width: 90%;
        margin: 0 auto;
    }

    .nav-title {
        font-size: 12px;

    }

    .date-picker {
        padding: 12px 0 12px 0;
        max-width: 300px;
    }

    .first-container {
        flex-direction: column;
        gap: 22px;
    }

    ::v-deep .v-label--active {
        font-size: 16px !important;
        left: 0px !important;
        margin-left: 25px;
        line-height: 15.73px;
        color: #98a2b2 !important;
    }

    #custom-toolbar ::v-deep .v-toolbar__content {
        height: 100% !important;
        width: 100% !important;
    }

    .title {
        font-size: 18px !important;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .toolbar-container {
        flex-direction: column;
        height: 100% !important;
    }

    .toolbar-buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }

    .restaurant-input {
        padding: 5px 0 12px 0 !important;
        min-width: 240px !important;

    }

}


/* @media (max-width: 1440px) {
    .doughnut-border {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        max-width: 478px;
        width: 100%;
    }
} */

@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
        /* background-color: #E4E4E4; */
        /* border-radius: 8px; */
        /* margin-left: 16px;
        margin-right: 30px;
        padding: 0px !important; */
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
        /* margin-left: 16px;
        margin-right: 30px;
        padding: 0px !important; */
    }
}
</style>
